@use "module";


@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@400;600;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&family=Roboto+Condensed&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500&display=swap');

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: bottom;
	text-decoration: none;
	box-sizing: border-box;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
img {
  vertical-align: bottom;
  max-width: 100%;
  width: auto;
  height: auto;
}

input[type="submit"],
input[type="button"],
button {
  border-radius: 0;
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  appearance: button;
  border: none;
  box-sizing: border-box;
    &::-webkit-search-decoration {
      display: none;
    }
    &::focus {
      outline-offset: -2px;
    }
}

.iframe-wrap{
  position:relative;
  width:100%;
  height:0;
  padding-bottom:56.25%;
	iframe{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
  }
}

@include module.tab {
  .pc {
    display: none !important;
  }
}
@include module.pc {
  .sp {
    display: none !important;
  }
}

html{
	font-size: 62.5%;
}

body{
	font-family: module.$noto;
	font-size: 1.4rem;
  color: module.$bl;
  display: flex;
  flex-direction:column;
  min-height:100vh;
  &.fixed{
    overflow: hidden;
  }
}

a:not(.no-link){
  color: module.$bl;
  img{
    transition: opacity .3s ease;
  }
  &:hover{
    img{
      opacity: 0.7;
    }
  }
}

.bold{
  font-weight: bold;
}

.noto{
  font-family: module.$noto;
}

.lora{
  font-family: module.$lora;
}

.roboto{
  font-family: module.$roboto;
}

.sans{
  font-family: module.$sans;
}

.rd{
  color: module.$rd;
}

.boxed{
  border: solid 1px #D8D8D8;
  padding: 18px 3%;
  @include module.tab{
    padding: 18px 6%;
  }
}

.list-indent{
  font-size: 1.4rem;
  letter-spacing: 0.1em;
  line-height: 2;
  padding-left: 30px;
  &__item{
    padding-left: 1.5rem;
    text-indent: -1.5rem;
  }
  @include module.tab{
    font-size: 1.3rem;
    line-height: calc(25 / 13);
    padding-left: 20px;
  }
}

/*　ボタン　ここから　*/

.underline{
  text-decoration:underline;
  &:hover{
    text-decoration:none;
  }
}

.btn-arrow{
  max-width:100%;
  display: inline-block;
  font-size: 1.6rem;
  letter-spacing: 0.1em;
  font-family: module.$lora;
  font-weight: module.$medium;
  color: module.$bl;
  background: #F4F4F4;
  border: solid 1px module.$bl;
  border-radius:100px;
  line-height: calc(22 / 16);
  padding: 23px 67px 23px 23px;
  position: relative;
  cursor: pointer;
  transition:.3s ease;
  .arrow{
    width: 40px;
    height: 40px;
    border-radius:50%;
    background: #fff;
    position: absolute;
    right:10px;
    top: 50%;
    transform: translateY(-50%);
    &:before,
    &:after{
      content:"";
      display: block;
      position: absolute;
      right:14px;
      top:50%;
    }
    &:before{
      width: 16px;
      height: 1px;
      background: module.$bl;
      transform: translateY(-50%);
    }
    &:after{
      width: 10px;
      height: 10px;
      border-top: solid 1px module.$bl;
      border-right: solid 1px module.$bl;
      transform: translateY(-50%) rotate(45deg);
    }
    &.down{
      &:before{
        width: 1px;
        height: 16px;
        right:50%;
      }
      &:after{
        border-top:none;
        border-bottom: solid 1px module.$bl;
        right:15px;
      }
    }
  }
  &:hover{
    background: module.$bl;
    color: #fff;
  }
  @include module.tab{
    padding: 18px 70px 18px 20px;
    .arrow{
      width: 30px;
      height: 30px;
      &:before,
      &:after{
        right: 8px;
      }
      &.down{
        &:after{
          right:10px;
        }
      }
    }
  }
  &.line2{
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

.link-arrow{
  color: module.$bl;
  padding-left: 24px;
  position: relative;
  display: inline-block;
  .arrow{
    position: absolute;
    width: 18px;
    height: 18px;
    background: module.$bl;
    border-radius: 50%;
    left:0;
    top:-2px;
    &:after{
      display: block;
      content:"";
      position: absolute;
      width: 5px;
      height: 5px;
      border-right: solid 2px #fff;
      border-top: solid 2px #fff;
      left:4px;
      top:50%;
      transform: translateY(-50%) rotate(45deg);
    }
  }
  &:hover{
    text-decoration:underline;
  }
}

.link-blank{
  padding-right: 17px;
  position: relative;
  display: inline-block;
  &:after{
    content:url(../img/common/ic_blank.svg);
    position: absolute;
    right:0;
  }
}

/*　ボタン　ここまで　*/


/*　見出し　ここから　*/

.under-ttl{
  width: 960px;
  max-width: 90%;
  margin: 0 auto;
  .en{
    font-family: module.$lora;
    font-size: 3.8rem;
    font-weight: bold;
  }
  .jp{
    font-size: 1.2rem;
    letter-spacing: 0.2em;
    display: block;
    margin-top: 8px;
  }
  @include module.tab{
    .en{
      font-size: 3rem;
    }
  }
  @include module.sp{
    width: 315px;
  }
}

.ttl-border{
  font-size: 3rem;
  letter-spacing: 0.1em;
  font-weight: bold;
  line-height: calc(44 / 30);
  padding-bottom: 15px;
  border-bottom: 1px solid #D8D8D8;
  position: relative;
  margin-bottom: 15px;
  &:before{
    content:"";
    display: block;
    position: absolute;
    left:0;
    bottom:-1px;
    height: 2px;
    width: 72px;
    background: module.$bl;
  }
  @include module.tab{
    font-size: 2.6rem;
  }
}

.ttl-left{
  font-size: 1.6rem;
  line-height: calc(28 / 16);
  letter-spacing: 0.1em;
  font-weight: bold;
  padding-left: 12px;
  position: relative;
  &:before{
    content:"";
    display: block;
    position: absolute;
    left:0;
    top:.5rem;
    bottom:.5rem;
    width: 4px;
    background: module.$bl;
  }
}

.ttl-bg{
  font-size: 1.6rem;
  font-weight: bold;
  letter-spacing: 0.1em;
  line-height: calc(28 / 16);
  background: #F6F6F6;
  padding: 3px 5px 3px 20px;
  position: relative;
  margin-bottom: 14px;
  &:before{
    content:"";
    display: block;
    position: absolute;
    left: 7px;
    top:.8rem;
    bottom:.8rem;
    width: 4px;
    background: module.$bl;
  }
}

/*　見出し　ここまで　*/

.dl-col2{
  display: flex;
  flex-wrap:wrap;
  &__dt,
  &__dd{
    padding: 27px 0;
    border-bottom: 1px solid #D8D8D8;
  }
  &__dt{
    width: 23%;
    .txt{
      font-size: 1.6rem;
      font-weight: bold;
      letter-spacing: 0.1em;
      line-height: calc(28 / 16);
    }
  }
  &__dd{
    width: 77%;
    padding-left: 5px;
    .txt{
      font-size: 1.6rem;
      letter-spacing: 0.1em;
      line-height: calc(28 / 16);
    }
    .list-ttl{
      font-size: 1.6rem;
      font-weight: bold;
      letter-spacing: 0.1em;
      line-height: calc(28 / 16);
      margin-bottom: 5px;
    }
  }
  @include module.tab{
    display: block;
    &__dt{
      width: 100%;
      border-bottom: none;
      padding: 18px 0 1px;
    }
    &__dd{
      width: 100%;
      padding: 0 0 15px;
      .txt{
        font-size: 1.4rem;
        line-height: 2;
      }
      .list-ttl{
        font-size: 1.4rem;
        line-height: 2;
        margin-bottom: 3px;
      }
    }
  }
}

.news{
  &__list{
    display: flex;
    flex-wrap:wrap;
  }
  &__link{
    display: block;
    &:hover{
      text-decoration:underline;
      .img-wrap{
        opacity: 0.7;
      }
    }
  }
  &__item{
    @include module.pc{
      width: calc((100% - 6px) / 3);
      margin-right: 3px;
      &:nth-of-type(3n){
        margin-right: 0;
      }
      &:nth-of-type(n+4){
        margin-top: 20px;
      }
    }
    @include module.tab{
      width: calc((100% - 1px) / 2);
      margin-right: 1px;
      &:nth-of-type(even){
        margin-right: 0;
      }
      &:nth-of-type(n+3){
        margin-top: 2px;
      }
    }
    .img-wrap{
      background: center center /cover no-repeat;
      padding-bottom: 57%;
      transition:.3s ease;
      @include module.tab{
        padding-bottom: 100%;
      }
    }
    .txt-wrap{
      padding: 23px 4%;
      color: module.$bl;
      .ttl{
        font-size: 1.4rem;
        letter-spacing: 0.1em;
        line-height: calc(20 / 14);

      }
      @include module.tab{
        padding: 18px 7%;
        .ttl{
          font-size: 1.2rem;
          line-height: calc(18 / 12);
        }
      }
    }
  }
  .date{
    font-family: module.$roboto-c;
    font-size: 1.4rem;
    letter-spacing: 0.1em;
    margin-bottom: 9px;
  }
  .cat-row{
    margin: 0 -5px 6px;
  }
  .cat{
    font-family: module.$sans;
    font-size: 1.2rem;
    letter-spacing: 0.1em;
    color: #fff;
    background: module.$bl;
    border-radius:100px;
    padding: 5px 8px;
    display: inline-block;
    margin: 0 5px 5px;
    &__link{
      color: #fff;
    }
  }
  @include module.tab{
    .date{
      margin-bottom: 5px;
    }
    .cat-row{
      margin-bottom: 0;
    }
    .cat{
      font-size: 1rem;
    }
  }
}

.pagination{
  width: 960px;
  max-width: 90%;
  margin: 79px auto 0;
  @include module.tab{
    margin-top: 61px;
  }
  ul{
    display: flex;
    justify-content: center;
    li{
      width: 32px;
      height: 32px;
      margin: 0 15px;
      @include module.tab{
        margin: 0 calc((100% - 224px) / 14);
      }
    }
    .page-numbers{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      text-align: center;
      border-radius:50%;
      font-family: module.$lora;
      font-size: 1.6rem;
      color: #8A8A8A;
      transition:.3s ease;
      &.current,
      &:hover{
        background: #F6F6F6;
        color: module.$bl;
      }
      &.prev,
      &.next{
        border: solid 1px module.$bl;
        position: relative;
        &:after{
          content:"";
          display: block;
          position: absolute;
          width: 10px;
          height: 10px;
          border-top: solid 2px module.$bl;
          border-right: solid 2px module.$bl;
        }
        &:hover{
          background: module.$bl;
          &:after{
            border-color:#fff;
          }
        }
      }
      &.prev{
        &:after{
          transform:rotate(-135deg);
          left: calc(50% - 3px);
        }
      }
      &.next{
        &:after{
          transform:rotate(45deg);
          right: calc(50% - 3px);
        }
      }
    }
  }
}


.header{
  padding: 30px 0 39px;
  background: #fff;
  text-align: center;
  @include module.tab{
    padding: 20px 5% 20px;
    text-align: left;
    position: relative;
  }
  &__logo-wrap{
    margin-bottom: 31px;
    @include module.tab{
      max-width:132px;
      margin: 0;
    }
  }
  &__logo-link{
    display: block;
    @include module.tab{
      background: url(../img/common/header_logo_wh.svg) center center /contain no-repeat;
    }
  }
  &__nav{
    .menu{
      display: flex;
      justify-content: center;
      &__item{
        margin: 0 1%;
        @include module.pc{
          &.current .menu__link{
            color: #8A8A8A;
            border-color: #8A8A8A;
          }
        }
      }
      &__link{
        color: module.$bl;
        font-family: module.$lora;
        font-weight: module.$bold;
        padding-bottom: 7px;
        border-bottom: 1px solid transparent;
        transition:.3s ease;
        &:hover{
          color: #8A8A8A;
          border-color: #8A8A8A;
        }
      }
    }
    @include module.tab{
      display: none;
      position: absolute;
      top:59px;
      left:0;
      right:0;
      z-index: 999;
      height: calc(100vh - 59px);
      background: module.$bl;
      padding: 49px 8%;
      overflow: auto;
      .menu{
        display: block;
        &__item{
          padding: 25px 13% 18px;
          margin: 0;
          &:nth-of-type(n+2){
            border-top: 1px solid #fff;
          }
        }
        &__link{
          font-weight: module.$s-bold;
          padding: 0;
          border:none;
          color: #fff;
          font-size: 1.8rem;
        }
      }
    }
  }
  .menu-trigger{
    position: absolute;
    display: block;
    right:5%;
    top:18px;
    z-index: 999;
    width: 28px;
    height: 20px;
    cursor: pointer;
    span{
      display: inline-block;
      width: 28px;
      height: 3px;
      background: module.$bl;
      position: absolute;
      right:50%;
      transform:translateX(50%);
      &:nth-of-type(1){
        top:0;
      }
      &:nth-of-type(2){
        top:50%;
      }
      &:nth-of-type(3){
        top:100%;
      }
    }
  }
  &.active{
    background: module.$bl;
    .header__logo-link{
      img{
        opacity: 0;
      }
    }
    .menu-trigger{
      width: 50px;
      height: 50px;
      border: solid 1px #fff;
      border-radius: 50%;
      top:15px;
      right:4%;
      span{
        background: #fff;
        width: 32px;
        height: 1px;
        right:50%;
        &:nth-of-type(1){
          top:50%;
          transform:translateX(50%) rotate(36deg);
        }
        &:nth-of-type(2){
          opacity: 0;
        }
        &:nth-of-type(3){
          top:50%;
          transform:translateX(50%) rotate(-36deg);
        }
      }
    }
  }
}

.footer{
  margin-top: auto;
  background: #F6F6F6;
  padding: 50px 0 35px;
  @include module.tab{
    padding: 40px 5% 42px;
  }
  &__logo-wrap{
    text-align: center;
    margin-bottom: 102px;
    @include module.tab{
      margin-bottom: 40px;
    }
  }
  &__row{
    width: 960px;
    max-width: 90%;
    display: flex;
    justify-content: flex-end;
    margin: 0 auto 114px;
    @include module.tab{
      display: block;
      max-width: 100%;
      margin-bottom: 82px;
    }
  }
  &__nav{
    width: calc(100% - 640px);
    .menu{
      &__item{
        &:nth-of-type(n+2){
          margin-top: 36px;
        }
      }
      &__link{
        color: module.$bl;
        font-family: module.$lora;
        font-weight: module.$bold;
        padding-bottom: 5px;
        border-bottom: 1px solid transparent;
        transition:.3s ease;
        &:hover{
          color: #8A8A8A;
          border-color: #8A8A8A;
        }
      }
    }
    @include module.tab{
      max-width:272px;
      width: 100%;
      margin: 0 auto 43px;
      .menu{
        height: 120px;
        display: flex;
        flex-direction:column;
        flex-wrap:wrap;
        &__item{
          width: 68%;
          &:nth-of-type(n+2){
            margin-top: 18px;
          }
          &:nth-of-type(n+5){
            width: 32%;
          }
          &:nth-of-type(5){
            margin-top: 0;
          }
        }
      }
    }
  }
  &__unit{
    @include module.pc{
      width: 640px;
      display: flex;
      flex-wrap:wrap;
      justify-content: space-between;
    }
  }
  &__box{
    width: 49%;
    position: relative;
    @include module.pc{
      &:nth-of-type(n+3){
        margin-top: 20px;
      }
    }
    @include module.tab{
      max-width:315px;
      width: 100%;
      margin: 0 auto;
      padding: 0;
      &+.footer__box{
        margin-top: 48px;
      }
    }
    .menu-ttl{
      font-size: 1.6rem;
      font-weight: module.$s-bold;
      letter-spacing: 0.1em;
      margin-bottom: 18px;
      &__link{
        color: module.$bl;
        padding-left: 24px;
        position: relative;
        &:before,
        &:after{
          display: block;
          content:"";
          position: absolute;
        }
        &:before{
          width: 18px;
          height: 18px;
          background: module.$bl;
          border-radius: 50%;
          left:0;
        }
        &:after{
          width: 5px;
          height: 5px;
          border-right: solid 2px #fff;
          border-top: solid 2px #fff;
          transform: rotate(45deg);
          left:4px;
          top:10px;
        }
        &:hover{
          text-decoration:underline;
        }
      }
    }
    .sub-menu{
      padding-left: 20px;
      &__item{
        &:nth-of-type(n+2){
          margin-top: 16px;
        }
      }
      &__link{
        letter-spacing: 0.1em;
        color: #707070;
        position: relative;
        padding-left: 14px;
        display: block;
        &:before{
          content:"";
          display: block;
          width: 5px;
          height: 5px;
          border-right: solid 2px #8A8A8A;
          border-top: solid 2px #8A8A8A;
          transform: rotate(45deg);
          position: absolute;
          left:0;
          top:4px;
        }
        &:hover{
          text-decoration:underline;
        }
      }
      @include module.tab{
        margin-bottom: 26px;
      }
    }
    .sns{
      display: flex;
      justify-content: space-between;
      &__box{
        width: 49%;
      }
      &__ttl{
        font-size: 1.2rem;
        letter-spacing: 0.05em;
        line-height: 1.2;
        margin-bottom: 10px;
      }
      &__row{
        display: flex;
      }
      &__link{
        &:nth-of-type(n+2){
          margin-left: 12px;
        }
      }
      @include module.tab{
        &__box{
          position: relative;
          padding-bottom: 50px;
        }
        &__ttl{
          margin-bottom: 5px;
        }
        &__row{
          position: absolute;
          bottom:0;
        }
      }
    }
  }
  .copyright{
    font-family: module.$lora;
    font-size: 1rem;
    letter-spacing: .1em;
    text-align: center;
  }
}