@charset "utf-8";

$pc:1025px;
$tab:1024px;
$sp:599px;

$noto: 'Noto Serif JP', serif;
$lora:  'Lora', 'Noto Serif JP', serif;
$roboto: 'Roboto', sans-serif;
$roboto-c: 'Roboto Condensed', sans-serif;
$sans: 'Noto Sans JP', sans-serif;;

$light:300;
$medium: 500;
$s-bold: 600;
$bold: 700;
$e-bold: 800;
$black: 900;

$bl: #171717;
$rd: #D90000;

@mixin pc {
  @media (min-width: ($pc)) {
    @content;
  }
}
@mixin tab {
  @media (max-width: ($tab)) {
    @content;
  }
}
@mixin sp {
  @media (max-width: ($sp)) {
    @content;
  }
}

$baseWidth : 1600;
@function vw($pixelsVw) {
    @return $pixelsVw / $baseWidth * 100vw;
  }

@mixin fz9{
    display: block;
    font-size: 1rem;
    transform:scale(0.9);
    transform-origin: left center;
    width: calc(100% / .9);
}

