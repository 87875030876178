@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@400;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lora:wght@500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto&family=Roboto+Condensed&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500&display=swap");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: bottom;
  text-decoration: none;
  box-sizing: border-box;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img {
  vertical-align: bottom;
  max-width: 100%;
  width: auto;
  height: auto;
}

input[type=submit],
input[type=button],
button {
  border-radius: 0;
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  appearance: button;
  border: none;
  box-sizing: border-box;
}
input[type=submit]::-webkit-search-decoration,
input[type=button]::-webkit-search-decoration,
button::-webkit-search-decoration {
  display: none;
}
input[type=submit]::focus,
input[type=button]::focus,
button::focus {
  outline-offset: -2px;
}

.iframe-wrap {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}
.iframe-wrap iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 1024px) {
  .pc {
    display: none !important;
  }
}
@media (min-width: 1025px) {
  .sp {
    display: none !important;
  }
}
html {
  font-size: 62.5%;
}

body {
  font-family: "Noto Serif JP", serif;
  font-size: 1.4rem;
  color: #171717;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
body.fixed {
  overflow: hidden;
}

a:not(.no-link) {
  color: #171717;
}
a:not(.no-link) img {
  transition: opacity 0.3s ease;
}
a:not(.no-link):hover img {
  opacity: 0.7;
}

.bold {
  font-weight: bold;
}

.noto {
  font-family: "Noto Serif JP", serif;
}

.lora {
  font-family: "Lora", "Noto Serif JP", serif;
}

.roboto {
  font-family: "Roboto", sans-serif;
}

.sans {
  font-family: "Noto Sans JP", sans-serif;
}

.rd {
  color: #D90000;
}

.boxed {
  border: solid 1px #D8D8D8;
  padding: 18px 3%;
}
@media (max-width: 1024px) {
  .boxed {
    padding: 18px 6%;
  }
}

.list-indent {
  font-size: 1.4rem;
  letter-spacing: 0.1em;
  line-height: 2;
  padding-left: 30px;
}
.list-indent__item {
  padding-left: 1.5rem;
  text-indent: -1.5rem;
}
@media (max-width: 1024px) {
  .list-indent {
    font-size: 1.3rem;
    line-height: 1.9230769231;
    padding-left: 20px;
  }
}

/*　ボタン　ここから　*/
.underline {
  text-decoration: underline;
}
.underline:hover {
  text-decoration: none;
}

.btn-arrow {
  max-width: 100%;
  display: inline-block;
  font-size: 1.6rem;
  letter-spacing: 0.1em;
  font-family: "Lora", "Noto Serif JP", serif;
  font-weight: 500;
  color: #171717;
  background: #F4F4F4;
  border: solid 1px #171717;
  border-radius: 100px;
  line-height: 1.375;
  padding: 23px 67px 23px 23px;
  position: relative;
  cursor: pointer;
  transition: 0.3s ease;
}
.btn-arrow .arrow {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.btn-arrow .arrow:before, .btn-arrow .arrow:after {
  content: "";
  display: block;
  position: absolute;
  right: 14px;
  top: 50%;
}
.btn-arrow .arrow:before {
  width: 16px;
  height: 1px;
  background: #171717;
  transform: translateY(-50%);
}
.btn-arrow .arrow:after {
  width: 10px;
  height: 10px;
  border-top: solid 1px #171717;
  border-right: solid 1px #171717;
  transform: translateY(-50%) rotate(45deg);
}
.btn-arrow .arrow.down:before {
  width: 1px;
  height: 16px;
  right: 50%;
}
.btn-arrow .arrow.down:after {
  border-top: none;
  border-bottom: solid 1px #171717;
  right: 15px;
}
.btn-arrow:hover {
  background: #171717;
  color: #fff;
}
@media (max-width: 1024px) {
  .btn-arrow {
    padding: 18px 70px 18px 20px;
  }
  .btn-arrow .arrow {
    width: 30px;
    height: 30px;
  }
  .btn-arrow .arrow:before, .btn-arrow .arrow:after {
    right: 8px;
  }
  .btn-arrow .arrow.down:after {
    right: 10px;
  }
}
.btn-arrow.line2 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.link-arrow {
  color: #171717;
  padding-left: 24px;
  position: relative;
  display: inline-block;
}
.link-arrow .arrow {
  position: absolute;
  width: 18px;
  height: 18px;
  background: #171717;
  border-radius: 50%;
  left: 0;
  top: -2px;
}
.link-arrow .arrow:after {
  display: block;
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  border-right: solid 2px #fff;
  border-top: solid 2px #fff;
  left: 4px;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
}
.link-arrow:hover {
  text-decoration: underline;
}

.link-blank {
  padding-right: 17px;
  position: relative;
  display: inline-block;
}
.link-blank:after {
  content: url(../img/common/ic_blank.svg);
  position: absolute;
  right: 0;
}

/*　ボタン　ここまで　*/
/*　見出し　ここから　*/
.under-ttl {
  width: 960px;
  max-width: 90%;
  margin: 0 auto;
}
.under-ttl .en {
  font-family: "Lora", "Noto Serif JP", serif;
  font-size: 3.8rem;
  font-weight: bold;
}
.under-ttl .jp {
  font-size: 1.2rem;
  letter-spacing: 0.2em;
  display: block;
  margin-top: 8px;
}
@media (max-width: 1024px) {
  .under-ttl .en {
    font-size: 3rem;
  }
}
@media (max-width: 599px) {
  .under-ttl {
    width: 315px;
  }
}

.ttl-border {
  font-size: 3rem;
  letter-spacing: 0.1em;
  font-weight: bold;
  line-height: 1.4666666667;
  padding-bottom: 15px;
  border-bottom: 1px solid #D8D8D8;
  position: relative;
  margin-bottom: 15px;
}
.ttl-border:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: -1px;
  height: 2px;
  width: 72px;
  background: #171717;
}
@media (max-width: 1024px) {
  .ttl-border {
    font-size: 2.6rem;
  }
}

.ttl-left {
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.1em;
  font-weight: bold;
  padding-left: 12px;
  position: relative;
}
.ttl-left:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0.5rem;
  bottom: 0.5rem;
  width: 4px;
  background: #171717;
}

.ttl-bg {
  font-size: 1.6rem;
  font-weight: bold;
  letter-spacing: 0.1em;
  line-height: 1.75;
  background: #F6F6F6;
  padding: 3px 5px 3px 20px;
  position: relative;
  margin-bottom: 14px;
}
.ttl-bg:before {
  content: "";
  display: block;
  position: absolute;
  left: 7px;
  top: 0.8rem;
  bottom: 0.8rem;
  width: 4px;
  background: #171717;
}

/*　見出し　ここまで　*/
.dl-col2 {
  display: flex;
  flex-wrap: wrap;
}
.dl-col2__dt, .dl-col2__dd {
  padding: 27px 0;
  border-bottom: 1px solid #D8D8D8;
}
.dl-col2__dt {
  width: 23%;
}
.dl-col2__dt .txt {
  font-size: 1.6rem;
  font-weight: bold;
  letter-spacing: 0.1em;
  line-height: 1.75;
}
.dl-col2__dd {
  width: 77%;
  padding-left: 5px;
}
.dl-col2__dd .txt {
  font-size: 1.6rem;
  letter-spacing: 0.1em;
  line-height: 1.75;
}
.dl-col2__dd .list-ttl {
  font-size: 1.6rem;
  font-weight: bold;
  letter-spacing: 0.1em;
  line-height: 1.75;
  margin-bottom: 5px;
}
@media (max-width: 1024px) {
  .dl-col2 {
    display: block;
  }
  .dl-col2__dt {
    width: 100%;
    border-bottom: none;
    padding: 18px 0 1px;
  }
  .dl-col2__dd {
    width: 100%;
    padding: 0 0 15px;
  }
  .dl-col2__dd .txt {
    font-size: 1.4rem;
    line-height: 2;
  }
  .dl-col2__dd .list-ttl {
    font-size: 1.4rem;
    line-height: 2;
    margin-bottom: 3px;
  }
}

.news__list {
  display: flex;
  flex-wrap: wrap;
}
.news__link {
  display: block;
}
.news__link:hover {
  text-decoration: underline;
}
.news__link:hover .img-wrap {
  opacity: 0.7;
}
@media (min-width: 1025px) {
  .news__item {
    width: calc((100% - 6px) / 3);
    margin-right: 3px;
  }
  .news__item:nth-of-type(3n) {
    margin-right: 0;
  }
  .news__item:nth-of-type(n+4) {
    margin-top: 20px;
  }
}
@media (max-width: 1024px) {
  .news__item {
    width: calc((100% - 1px) / 2);
    margin-right: 1px;
  }
  .news__item:nth-of-type(even) {
    margin-right: 0;
  }
  .news__item:nth-of-type(n+3) {
    margin-top: 2px;
  }
}
.news__item .img-wrap {
  background: center center/cover no-repeat;
  padding-bottom: 57%;
  transition: 0.3s ease;
}
@media (max-width: 1024px) {
  .news__item .img-wrap {
    padding-bottom: 100%;
  }
}
.news__item .txt-wrap {
  padding: 23px 4%;
  color: #171717;
}
.news__item .txt-wrap .ttl {
  font-size: 1.4rem;
  letter-spacing: 0.1em;
  line-height: 1.4285714286;
}
@media (max-width: 1024px) {
  .news__item .txt-wrap {
    padding: 18px 7%;
  }
  .news__item .txt-wrap .ttl {
    font-size: 1.2rem;
    line-height: 1.5;
  }
}
.news .date {
  font-family: "Roboto Condensed", sans-serif;
  font-size: 1.4rem;
  letter-spacing: 0.1em;
  margin-bottom: 9px;
}
.news .cat-row {
  margin: 0 -5px 6px;
}
.news .cat {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 1.2rem;
  letter-spacing: 0.1em;
  color: #fff;
  background: #171717;
  border-radius: 100px;
  padding: 5px 8px;
  display: inline-block;
  margin: 0 5px 5px;
}
.news .cat__link {
  color: #fff;
}
@media (max-width: 1024px) {
  .news .date {
    margin-bottom: 5px;
  }
  .news .cat-row {
    margin-bottom: 0;
  }
  .news .cat {
    font-size: 1rem;
  }
}

.pagination {
  width: 960px;
  max-width: 90%;
  margin: 79px auto 0;
}
@media (max-width: 1024px) {
  .pagination {
    margin-top: 61px;
  }
}
.pagination ul {
  display: flex;
  justify-content: center;
}
.pagination ul li {
  width: 32px;
  height: 32px;
  margin: 0 15px;
}
@media (max-width: 1024px) {
  .pagination ul li {
    margin: 0 calc((100% - 224px) / 14);
  }
}
.pagination ul .page-numbers {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  border-radius: 50%;
  font-family: "Lora", "Noto Serif JP", serif;
  font-size: 1.6rem;
  color: #8A8A8A;
  transition: 0.3s ease;
}
.pagination ul .page-numbers.current, .pagination ul .page-numbers:hover {
  background: #F6F6F6;
  color: #171717;
}
.pagination ul .page-numbers.prev, .pagination ul .page-numbers.next {
  border: solid 1px #171717;
  position: relative;
}
.pagination ul .page-numbers.prev:after, .pagination ul .page-numbers.next:after {
  content: "";
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  border-top: solid 2px #171717;
  border-right: solid 2px #171717;
}
.pagination ul .page-numbers.prev:hover, .pagination ul .page-numbers.next:hover {
  background: #171717;
}
.pagination ul .page-numbers.prev:hover:after, .pagination ul .page-numbers.next:hover:after {
  border-color: #fff;
}
.pagination ul .page-numbers.prev:after {
  transform: rotate(-135deg);
  left: calc(50% - 3px);
}
.pagination ul .page-numbers.next:after {
  transform: rotate(45deg);
  right: calc(50% - 3px);
}

.header {
  padding: 30px 0 39px;
  background: #fff;
  text-align: center;
}
@media (max-width: 1024px) {
  .header {
    padding: 20px 5% 20px;
    text-align: left;
    position: relative;
  }
}
.header__logo-wrap {
  margin-bottom: 31px;
}
@media (max-width: 1024px) {
  .header__logo-wrap {
    max-width: 132px;
    margin: 0;
  }
}
.header__logo-link {
  display: block;
}
@media (max-width: 1024px) {
  .header__logo-link {
    background: url(../img/common/header_logo_wh.svg) center center/contain no-repeat;
  }
}
.header__nav .menu {
  display: flex;
  justify-content: center;
}
.header__nav .menu__item {
  margin: 0 1%;
}
@media (min-width: 1025px) {
  .header__nav .menu__item.current .menu__link {
    color: #8A8A8A;
    border-color: #8A8A8A;
  }
}
.header__nav .menu__link {
  color: #171717;
  font-family: "Lora", "Noto Serif JP", serif;
  font-weight: 700;
  padding-bottom: 7px;
  border-bottom: 1px solid transparent;
  transition: 0.3s ease;
}
.header__nav .menu__link:hover {
  color: #8A8A8A;
  border-color: #8A8A8A;
}
@media (max-width: 1024px) {
  .header__nav {
    display: none;
    position: absolute;
    top: 59px;
    left: 0;
    right: 0;
    z-index: 999;
    height: calc(100vh - 59px);
    background: #171717;
    padding: 49px 8%;
    overflow: auto;
  }
  .header__nav .menu {
    display: block;
  }
  .header__nav .menu__item {
    padding: 25px 13% 18px;
    margin: 0;
  }
  .header__nav .menu__item:nth-of-type(n+2) {
    border-top: 1px solid #fff;
  }
  .header__nav .menu__link {
    font-weight: 600;
    padding: 0;
    border: none;
    color: #fff;
    font-size: 1.8rem;
  }
}
.header .menu-trigger {
  position: absolute;
  display: block;
  right: 5%;
  top: 18px;
  z-index: 999;
  width: 28px;
  height: 20px;
  cursor: pointer;
}
.header .menu-trigger span {
  display: inline-block;
  width: 28px;
  height: 3px;
  background: #171717;
  position: absolute;
  right: 50%;
  transform: translateX(50%);
}
.header .menu-trigger span:nth-of-type(1) {
  top: 0;
}
.header .menu-trigger span:nth-of-type(2) {
  top: 50%;
}
.header .menu-trigger span:nth-of-type(3) {
  top: 100%;
}
.header.active {
  background: #171717;
}
.header.active .header__logo-link img {
  opacity: 0;
}
.header.active .menu-trigger {
  width: 50px;
  height: 50px;
  border: solid 1px #fff;
  border-radius: 50%;
  top: 15px;
  right: 4%;
}
.header.active .menu-trigger span {
  background: #fff;
  width: 32px;
  height: 1px;
  right: 50%;
}
.header.active .menu-trigger span:nth-of-type(1) {
  top: 50%;
  transform: translateX(50%) rotate(36deg);
}
.header.active .menu-trigger span:nth-of-type(2) {
  opacity: 0;
}
.header.active .menu-trigger span:nth-of-type(3) {
  top: 50%;
  transform: translateX(50%) rotate(-36deg);
}

.footer {
  margin-top: auto;
  background: #F6F6F6;
  padding: 50px 0 35px;
}
@media (max-width: 1024px) {
  .footer {
    padding: 40px 5% 42px;
  }
}
.footer__logo-wrap {
  text-align: center;
  margin-bottom: 102px;
}
@media (max-width: 1024px) {
  .footer__logo-wrap {
    margin-bottom: 40px;
  }
}
.footer__row {
  width: 960px;
  max-width: 90%;
  display: flex;
  justify-content: flex-end;
  margin: 0 auto 114px;
}
@media (max-width: 1024px) {
  .footer__row {
    display: block;
    max-width: 100%;
    margin-bottom: 82px;
  }
}
.footer__nav {
  width: calc(100% - 640px);
}
.footer__nav .menu__item:nth-of-type(n+2) {
  margin-top: 36px;
}
.footer__nav .menu__link {
  color: #171717;
  font-family: "Lora", "Noto Serif JP", serif;
  font-weight: 700;
  padding-bottom: 5px;
  border-bottom: 1px solid transparent;
  transition: 0.3s ease;
}
.footer__nav .menu__link:hover {
  color: #8A8A8A;
  border-color: #8A8A8A;
}
@media (max-width: 1024px) {
  .footer__nav {
    max-width: 272px;
    width: 100%;
    margin: 0 auto 43px;
  }
  .footer__nav .menu {
    height: 120px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  .footer__nav .menu__item {
    width: 68%;
  }
  .footer__nav .menu__item:nth-of-type(n+2) {
    margin-top: 18px;
  }
  .footer__nav .menu__item:nth-of-type(n+5) {
    width: 32%;
  }
  .footer__nav .menu__item:nth-of-type(5) {
    margin-top: 0;
  }
}
@media (min-width: 1025px) {
  .footer__unit {
    width: 640px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
.footer__box {
  width: 49%;
  position: relative;
}
@media (min-width: 1025px) {
  .footer__box:nth-of-type(n+3) {
    margin-top: 20px;
  }
}
@media (max-width: 1024px) {
  .footer__box {
    max-width: 315px;
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }
  .footer__box + .footer__box {
    margin-top: 48px;
  }
}
.footer__box .menu-ttl {
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: 0.1em;
  margin-bottom: 18px;
}
.footer__box .menu-ttl__link {
  color: #171717;
  padding-left: 24px;
  position: relative;
}
.footer__box .menu-ttl__link:before, .footer__box .menu-ttl__link:after {
  display: block;
  content: "";
  position: absolute;
}
.footer__box .menu-ttl__link:before {
  width: 18px;
  height: 18px;
  background: #171717;
  border-radius: 50%;
  left: 0;
}
.footer__box .menu-ttl__link:after {
  width: 5px;
  height: 5px;
  border-right: solid 2px #fff;
  border-top: solid 2px #fff;
  transform: rotate(45deg);
  left: 4px;
  top: 10px;
}
.footer__box .menu-ttl__link:hover {
  text-decoration: underline;
}
.footer__box .sub-menu {
  padding-left: 20px;
}
.footer__box .sub-menu__item:nth-of-type(n+2) {
  margin-top: 16px;
}
.footer__box .sub-menu__link {
  letter-spacing: 0.1em;
  color: #707070;
  position: relative;
  padding-left: 14px;
  display: block;
}
.footer__box .sub-menu__link:before {
  content: "";
  display: block;
  width: 5px;
  height: 5px;
  border-right: solid 2px #8A8A8A;
  border-top: solid 2px #8A8A8A;
  transform: rotate(45deg);
  position: absolute;
  left: 0;
  top: 4px;
}
.footer__box .sub-menu__link:hover {
  text-decoration: underline;
}
@media (max-width: 1024px) {
  .footer__box .sub-menu {
    margin-bottom: 26px;
  }
}
.footer__box .sns {
  display: flex;
  justify-content: space-between;
}
.footer__box .sns__box {
  width: 49%;
}
.footer__box .sns__ttl {
  font-size: 1.2rem;
  letter-spacing: 0.05em;
  line-height: 1.2;
  margin-bottom: 10px;
}
.footer__box .sns__row {
  display: flex;
}
.footer__box .sns__link:nth-of-type(n+2) {
  margin-left: 12px;
}
@media (max-width: 1024px) {
  .footer__box .sns__box {
    position: relative;
    padding-bottom: 50px;
  }
  .footer__box .sns__ttl {
    margin-bottom: 5px;
  }
  .footer__box .sns__row {
    position: absolute;
    bottom: 0;
  }
}
.footer .copyright {
  font-family: "Lora", "Noto Serif JP", serif;
  font-size: 1rem;
  letter-spacing: 0.1em;
  text-align: center;
}